#accountNav{
  #sn-site-logo{
    display: none;
  }
}
body:not(.logged_in){
  padding-top: 0px;
  #accountNav{
    display: none;
  }
}
.theme-nav-menu{
  .mobileBottom{
    .social{
      text-align: center;
    }
    &:first-of-type{
      display: block !important;
    }
    display: none !important;
  }
}
@media only screen and (max-width: 1024px) {
  body:not(.logged_in){
    #accountNav{
      display: block;
    }
  }
  #accountNav{
    z-index: 500;
  }
  html{
    .gsc-results-wrapper-overlay{
      left: 5%;
    }
    &.has-mobile-nav{
      #accountNav{
        padding-right: 0px;
      }
      &.page-manager-visible{
        #accountNav{
          padding-left: 0px;
        }
      }
    }
    .theme-nav-style-slide{
      transition: top 300ms ease-in-out;
      .theme-slide-nav-content{
        background: rgba(#fff, 0.95);
        #GoogleSearchBoxMobileNav{
          background: #d7d7d7;
          padding-right: 10px;
        }
      }
      .theme-nav-item{
        border-bottom: 2px solid #e4e4e4;
        > .theme-nav-link{
          color: $link-color;
          text-transform: uppercase;
          font-weight: bold;
          &:hover{
            background: transparent;
            color: $accent-color;
          }
        }
        span{
          &:hover{
            background: transparent;
          }
          &:before{
            color: $accent-color;
            font-size: 1em;
          }
        }
      }
      .theme-search-submit{
        background: #ccc;
        &:focus{
          background: $accent-color;
        }
        &:hover{
          background: $accent-color;
        }
      }
      .theme-nav-menus{
        //background: rgba(#fff, 0.95);
      }
      .theme-nav-forward{
        color: $accent-color;
      }
    }
    &.collapsed-mobile-nav{
      .theme-nav-style-slide{
        display: block;
        width: 100%;
        //right: auto !important;
        //left: 100%;
        transition:top 300ms;
        top:-100%;
        background: rgba(#fff, 0.95);
        .theme-nav-menu{
          position: relative;
        }
        .donate, .registerNow{
          background: #fff;
          padding: 0px;
          margin: auto;
          opacity: 0.9;
          .pageElement{
            padding: 0px;
            margin: auto;
            display: block;
            margin: auto;
            h4{
              text-align: center;
              a{
                text-align: center;
                color: #fff;
                text-transform: uppercase;
                padding: 15px;
                &:after{
                  display: none;
                }

              }
            }
          }
        }
        .donate{
          .pageElement{
            h4{
              a{
                background-color: $accent-color;
              }
            }
          }
        }
        .registerNow{
          .pageElement{
            h4{
              a{
                background-color: $link-color;
              }
            }
          }
        }
      }
      &.slide-nav-open{
        .theme-nav-style-slide{
          right:auto !important;
          left:0px;
          top:44px;
        }
      }
      &.slide-nav-open.page-manager-visible{
        #accountNav{
          padding-left: 0px;
        }
        .theme-nav-style-slide{
          padding-left: 60px;
        }
      }
    }
  }
  #accountNav{
    border-bottom: 5px solid $accent-color;
    background: #fff;
    #logout, #login{
      display: none !important;
    }
    .theme-slide-nav-toggle{
      display: inline-block !important;
      width: 44px;
      height: 44px;
      background: transparent;
      float: right;
      &:before {
        font-family: "FontAwesome";
        content: "\f0c9";
        width: 100%;
        display: block;
        font-size: 26px;
        padding: 7px 10px 10px 10px;
        color: $accent-color;
      }
    }
    #sn-site-logo{
      padding: 0px;
      position: relative;
      display: inline-block !important;
      width: 44px;
      height: 30px;
      margin: auto;
      left:auto;
      top:5px;
      display: inline-block;
      .sn-site-logo-wrap{
        width: 100%;
        height: 100%;
        display: block;
        left: auto;
        right: auto;
        .sn-site-logo-background{
          position: absolute;
          left: 0px;
          top: 0px;
          display: block;
          width: 100%;
          height: 100%;
          margin: auto;
          padding: 0px;
        }
      }
    }
  }
}
//layout edits
#siteContainer #yieldContent.row{
  padding-top: 30px;
}
.home #siteContainer #yieldContent.row{
  padding-top: 0px;
}
//fix for google search
.theme-search-modal{
  width: 0px;
  height: 0px;
}
.theme-search-close, .theme-search-title, .theme-search-modal .theme-search-box, .theme-search-modal .theme-search-close{
  display: none !important;
}
.theme-search-modal{
  border:none;
  background:transparent;
}
.theme-search-results .gsc-control-cse{
  background: transparent;
  border: 0px;
}
.theme-search-overlay{
  background:transparent;
}
// html.has-mobile-nav {
//   .theme-mobile-nav {
//     display: none;
//   }
// }
// html.has-mobile-nav.slide-nav-open {
//   .theme-mobile-nav {
//     display: block;
//   }
// }
