//SASS
.snFooterContainer {
  // background: url('../images/bg_pattern.png');
  // background-color: #f9f9f9;
  // background-size: 30%;
  .footer {
    .pageEl.link{
      max-width: 1200px;
      display: block;
      margin: 0 auto 20px;
    }
    h3 {
      font: 700 20px/20px $fontTwo;
      color: $highlight-color;
    }
    .text {
      address {
        font-style: normal;
        color: $medium_gray;
      }
    }
    .callUs .text address:last-of-type {
      &:before {
        font-family: FontAwesome;
        content: '\f003';
        padding-right: 5px;
      }
      &:hover:before {
        color: $highlight-color;
      }
    }
  }
}

//CSS

.footer {
    overflow:hidden;
}
.footer .link {
    width:100%;
    position:relative;
    background:#dc2826;
    min-height: 50px;
    border-bottom: 5px solid #041590;
    box-sizing: content-box;
    margin-bottom:20px;
}
.footer .link:before,
.footer .link:after {
    position:absolute;
    content:'';
    width:100%;
    height:50px;
    top:0;
    left:100%;
    background:#dc2826;
    border-bottom: 5px solid #041590;
}
.footer .link:after {
    left: -100%;
}

.footer .link .linkElement {
    margin: 0;
}

.footer .link .linkElement h4 a {
    background: transparent;
    box-shadow: none;
    line-height: 50px;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-size: 24px;
    position: relative;
}

.footer .link .linkElement .goToLink:after {
  color: white;
  content: "\f0e0";
  font-family: FontAwesome;
  display: inline-block;
  margin: -1px 0 0 10px;
  padding: 0;
  position: relative;
  font-size:24px;
}

.footer .site-logo,
.footer .callUs,
.footer .address,
.footer .social {
    display: block;
    width:24%;
    top: 0px;
    float: left;
}

.footer img.sn-site-logo-img {
    display: block;
    margin: auto;
    position: relative;
}

.footer .callUs .textBlockElement {
    width: initial;
    float: right;
    margin-right: 10%;
}

.footer .address .textBlockElement {
    width: initial;
    margin-left: 10%;
}

.footer .social .sn-social-media-list {
    width: initial;
    float: right;
}

.social .sn-social-media-list.lg .icon-facebook {
    color: #3b5998;
    border: solid 2px #3b5998;
    background: none;
    text-indent: -6px;
}
.social .sn-social-media-list.lg .icon-twitter {
    color: #00aced;
    border: solid 2px #00aced;
    background: none;
    text-indent: -2px;
}
.social .sn-social-media-list.lg .icon-gplus {
    color: #dd4b39;
    border: solid 2px #dd4b39;
    background: none;
    text-indent: -1px;
}
.social .sn-social-media-list.lg .icon-instagram {
    color: #3f729b;
    border: solid 2px #3f729b;
    background: none;
    text-indent: -4px;
}
.social .sn-social-media-list.lg .icon-linkedin {
    color: #007bb6;
    border: solid 2px #007bb6;
    background: none;
    text-indent: -3px;
}
@media only screen and (max-width: 1024px) {
    .footer .site-logo,
    .footer .callUs,
    .footer .address,
    .footer .social {
      display: block;
      width:100%;
      float: none;
      top: initial;
      text-align: center;
      margin-bottom: 20px;
    }
    .footer .callUs .textBlockElement,
    .footer .address .textBlockElement {
      width: initial;
      float: initial;
      margin: auto;
      display:inline-block;
      text-align:center;
    }
    .footer .social .sn-social-media-list {
      float: none;
    }
    .footer .link{
      text-align: center;
    }
    #siteFooter > ul li {
        display: block;
        text-align: center;
    }
    #siteFooter > ul {
        border-top: 2px solid lightgrey;
        padding-top: 20px;
    }

    #siteFooter li#poweredByNGIN a {
        display: inline-block;
    }
  .snFooterContainer .footer h3 {
    text-align: center !important;
  }
}


.header .pageEl {
  position: absolute;
  top: -44px;
  left: 50%;
  margin-left: -600px;
}
.header .social {
  margin-left: 180px;
  top: -68px;
}

.header .link .linkElement h4 a {
    background: transparent;
    box-shadow: none;
    color: lightgray;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
}

body.logged_in .header .login {
  display: none;
}

.header .registerNow {
    margin-left: 200px;
    width: 200px;
    text-align: center;
}

.header .donate {
    margin-left: 430px;
    width: 170px;
    text-align: center;
}

.header .linkElement {
    margin: 0;
}

.header .link {
    margin-left: -590px;
}

.header .link + .link {
    margin-left: -470px;
}

.header .linkElement h4 a:after {
    content: none;
}

div#topNav {
    margin-top: 50px;
}

.header {
    border-bottom: 2px solid lightgrey;
}

.header .registerNow, .header .donate {
    top: 18px;
}

#topNav .theme-search-bar .theme-search-box {
}

#topNav .theme-search-wrapper {
    overflow: visible;
}

div#GoogleSearchBoxTopNav {
    top: -48px;
}

.header .registerNow .linkElement h4 a {
    background: #dc2826;
    box-shadow: none;
}

.header .donate .linkElement h4 a {
    box-shadow: none;
}

.header .donate .linkElement h4 a,.header .registerNow .linkElement h4 a {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    z-index: 999;
}



#topNav {transition:margin-top 100ms;}
.nav-fixed #topNav {margin-top:-5px;}

@media only screen and (max-width: 1280px) {
    .header .link {
        margin-left: -540px;
    }
    .header .link + .link {
        margin-left: -440px;
    }
    .header .registerNow {
        margin-left: 140px;
    }

    .header .donate {
        margin-left: 370px;
    }
    .header .social {
        margin-left: 100px;
    }
    #topNav .theme-search-bar .theme-search-box ~ .theme-search-box {right: 60px;}
}
@media only screen and (max-width: 1160px) {
    .header .link {
        margin-left: -480px;
    }
    .header .link + .link {
        margin-left: -370px;
    }
    .header .registerNow {
        margin-left: 70px;
    }
    .header .donate {
        margin-left: 300px;
    }
    .header .social {
        margin-left: 1px;
    }
    #topNav .theme-search-bar .theme-search-box ~ .theme-search-box {right: 130px;}
}
@media only screen and (max-width: 1024px) {
  .header {
      display:none;
  }
  div#topNav {
      margin-top:0px;
  }
}

@media (max-width: 1280px) {
  #GoogleSearchBoxTopNav {
    right: 60px;
  }
}

@media (max-width: 1160px) {
  #GoogleSearchBoxTopNav {
    right: 130px;
  }
}
