
//Removes some default styles
html {
  overflow-x: hidden;
  #panelTwo {
    padding: 0;
  }
  .layoutContainer {
    padding-right: 0;
    padding-left: 0;
  }
}

html.has-sub-nav{
  body.home {
    .theme-sub-nav {
      display: none;
    }

    #siteContainer {
      padding-top: 44px; 
    }
  }
}



//Styles to make container full width
.content-wrapper {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
  background-image: url('../images/bg_pattern.png');
  background-color: #f9f9f9;
  background-size: 30%;
}

.mediaFullWidth-background {
  max-width: 1200px;
  margin: auto;
  padding: 55px 0;

  .flexslider{
    height: 700px !important;
  }
}

//full width media slider on HOME

body:not(.edit_mode) {
  .layoutContainer.row.layout-100.mediaFullWidth, .full-width-content {
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;
    transform: translateY(-1px);
    .mediaSlider > p {
      margin: 0;
    }

    .column-1.column.col-sm-12.col-md-12 {
      margin-bottom: -36px;
    }

    .media-wrapper img {
      height: auto;
      width: 100%;
      left: 0;
    }

    .layoutContainer .pageElement {
      margin: 0;
    }

    .slider-pagination {
      bottom: 58px;
    }

    .paging-item {
      color: transparent;
      background: transparent;
      border-style: solid;
      border-color: white;
      border-width: 2px;
      height: 23px;
      width: 23px;
      margin-left: 13px;
      vertical-align: top;

      &.flex-active {
        &:before {
          content: '';
          display: inline-block;
          width: 15px;
          height: 15px;
          -moz-border-radius: 100%;
          -webkit-border-radius: 100%;
          border-radius: 100%;
          background-color: $accent-color;
        }
      }
    }
    .slide-text {
      height: auto;
      height: inital;
      width: 60%;
      display: block;
      position: absolute;
      background-color: rgba(255,255,255,0.9);
      border-bottom: 10px solid $highlight-color;
      padding: 40px 30px;
      margin-top: initial;
      margin-left: initial;
      bottom: 20%;
      left: 10%;
    }

    .slide-title {
      color: $accent-color;
      font: 700 30px/30px $fontOne;
      text-transform: uppercase;
      padding-bottom: 20px;
    }

    .slide-description {
      color: $medium_gray;
      font: 500 15px/15px $fontTwo;
    }

    .slide-title, .slide-description {
      text-shadow: none;
      margin: 0;
    }
  }
}

@media (max-width: 768px) {
  .mobile-slide-text {
    padding-bottom: 30px;
    .slide-title, .slide-description {
      display: block;
    }
  }
}

@media (max-width: 1024px){
  body:not(.edit_mode) .layoutContainer.row.layout-100.mediaFullWidth .slide-text, body:not(.edit_mode) .full-width-content .slide-text {
    position: absolute;
    background-color: #fff;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
    border-bottom: none;
    padding: 10px;


    .slide-title {
      font: 700 20px/20px "Oswald", sans-serif;
    }

    .slide-description {
      font: 500 13px/13px "Droid Sans", sans-serif;
    }
  }

  body:not(.edit_mode) .layoutContainer.row.layout-100.mediaFullWidth .slider-pagination, body:not(.edit_mode) .full-width-content .slider-pagination {
    height: auto;
    background-color: $highlight-color;
    padding: 5px;
    bottom: 0;
  }

  body:not(.edit_mode) .layoutContainer.row.layout-100.mediaFullWidth, body:not(.edit_mode) .full-width-content {
    transform: translateY(-16px);
    .sn-media-slider .slides .slide .slide-overlay {
      padding: 0;

    }
  }

  .flexslider {
      box-sizing: content-box;
  }
  .sn-media-slider .flex-viewport:last-of-type {
      height: 200%;
  }
  .sn-media-slider .slides {
      height: 100%;
  }
  .sn-media-slider .slides .slide {
      height: 100%;
  }
  .sn-media-slider .slides .slide .slide-overlay {
      height: 50%;
      bottom: initial;
      top: 50%;
  }
  // body:not(.edit_mode) .layoutContainer.row.layout-100.mediaFullWidth .slide-text,
  // body:not(.edit_mode) .full-width-content .slide-text {
  //     height: 100%;
  // }
}

//custom tab element

.customTabHeader {
  margin-bottom: 30px;

  p {
    width: 100%;
    background-color: transparent;
    color: $highlight-color;
    font: 700 24px/24px $fontOne;
    text-transform: uppercase;
    box-shadow: none;
    padding: 0 0 5px 0;
    border-bottom: solid 3px $accent-color;

    &:after{
      content: "";
      display: block;
      position: relative;
      top: 11px;
      width: 100%;
      height: 2px;
      background: #041590;
    }
  }
}

.tabCustom {
  min-width: 100%;
  background-color: #fff;
  border: 3px solid $lt-gray;

  .contentTabs {
    padding: 0 15px;

    & li {
      margin-right: 30px;
      a {
        color: $gray;
        padding: 0 0 5px;
      }
    }

    & li:hover, li.selected {
      border-bottom: none;

      & a {
        color: $dark_gray;
        border-bottom: 5px solid $accent-color;
      }
    }
  }

  .layoutContainer .pageElement {
    margin: 0;
  }

  .layoutContainer.row.layout-100  {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .tabCustom .tabbedElement {
    .contentTabs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 24%;
      margin: auto;
      padding: 0;

      > li {
        display: block;
        width: auto;
        margin: auto;
      }
    }

    iframe {
      width: 340px;
      display: block;
      margin: 0 auto;
    }
  }
}

//Remove border form single photos

.noBorder img{
  border: none;
}
