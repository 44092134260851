/* Venue Status Page Element */
// 1. Custom Styles
// 2. Mixins
// 3. Base Styles
// 4. Breakpoints

// 1. Custom Styles

$venue-font-small: 13px;
$venue-font-large: 15px;


.venueStacked {
  width: 100%;
  display: inline-block;
  vertical-align: top;

  .venueElement .dataTable {
    margin-top: 0;
    .heading {
      display: block;
      height: 80px;
      background-color: white;
      padding: 0;

      .title {
        .venue_heading, .update {
          display: block;
          width: 100%;
        }

        .venue_heading {
          color: #fff;
          background-color: $accent-color;
          border-bottom: 5px solid $highlight-color;
          font: 700 16px/16px Droid Sans;
          padding: 10px;
          text-transform: uppercase;
        }

        .update {
          color: $gray;
          font: 500 13px/13px $fontTwo;
          letter-spacing: 1px;
          text-transform: none;
          margin: 13px 0; 
        }
      }
    }
    tbody > tr {
      padding-top: 5px;
      width: 100%;
      display: block;
      position: relative;
      border: 2px solid $lt-gray;
      border-bottom: none;

      &:last-of-type {
        border-bottom: 2px solid $lt-gray;
      }

      > td {
        width: 100%;
        display: block;
        background-color: #fff;

        &:last-of-type {
          padding-bottom: 5px;
        }
      }

      .venue_name {
        color: $medium_gray;
        font-size: $venue-font-large;

      }

      .txt_open, .txt_closed {
        font-size: $venue-font-large;
        font-weight: 700;
      }

      .venue_descr {
        display: block;
        position: relative;
        color: $medium_gray;
        font-size: $venue-font-small;
      }
    }
  }
}

//custom bottom link under venue

.venue-customLink .linkElement a {
  background-color: #fff;
  color: $accent-color;
  text-decoration: underline;
  box-shadow: none;

  &:after {
    display: none;
  }

  &:hover {
    background-color: white;
  }
}


// 2. Base Styles
@mixin table-venue-element-mini {
  .venueElement .dataTable {
    td {
      border-right: none;
      display: block;
      width: 100%;
      height: auto;
    }
    .venue_name {
      font-weight: bold;
    }
    .txt_open,
    .txt_closed,
    .txt_pending {
      text-align: left;
    }
  }
}


// 3. Base Styles
.venueElement .dataTable {
  $status-color-open: #02A218;
  $status-color-closed: #E50000;
  $status-color-pending: #929292;

  .heading {
    .title {
      @include table-th-text;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 100%;
      white-space: normal;
    }
  }

  .venue_heading {
    margin-right: 5px;
  }

  td {
    text-align: left;
  }

  .venue_name {
    font-weight: bold;
  }

  .txt_open { color: $status-color-open; }
  .txt_closed { color: $status-color-closed; }
  .txt_pending { color: $status-color-pending; }

  .img_open,
  .img_closed,
  .img_pending {
    display: inline-block;
    height: .6em;
    margin-right: .5em;
    border-radius: 100%;
    display: none;
  }

  .img_open { background-color: $status-color-open; }
  .img_closed { background-color: $status-color-closed; }
  .img_pending { background-color: $status-color-pending; }
}


// 4. Breakpoints
@media (max-width: 480px) {
  @include table-venue-element-mini;
}

@media (min-width: 768px) {
  .col-md-3,
  .col-md-4 {
    @include table-venue-element-mini;
  }
}

@media (min-width: 769px) and (max-width: 999px) {
  .layout-25-50-25 .column {
    @include table-venue-element-mini;
  }
}
